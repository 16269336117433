
import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import { pagesRoutes } from './pages';
import { local } from '~/utils/cache';
import { STORAGE_KEYS } from '~/const/storage-keys';
import { WHITE_ROUTER_LIST } from '~/const/white-route';
import { USER_STORE } from '~/stores/user';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'layout',
    redirect: '/home',
    component: () => import('~/views/layout/index.vue'),
    children: pagesRoutes
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('~/views/user-enter/login/index.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('~/views/user-enter/register/index.vue')
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: () => import('~/views/user-enter/forgot/index.vue')
  },
  {
    path: '/:pathMatch(.*)',
    name: 'not-found',
    component: () => import('~/views/error/not-found.vue'),
    meta: { title: '404' },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

// 路由守卫
router.beforeEach(async (to, form, next) => {
  const token = local.get<string>(STORAGE_KEYS.TOKEN);
  // 若获取到token
  if (token) {
    // 若路由为登录页面
    if (to.path === '/login') {
      // 则进入默认首页
      next('/');
    // 若路由存在于免登录白名单
    } else if (WHITE_ROUTER_LIST.includes(to.path)) {
      next();
    } else {
      // 若用户没有权限
      if (!USER_STORE().userInfo) {
        // 获取用户信息
        const bool = await USER_STORE().GET_USER_INFO();
        if (bool) {
          next({ ...to, replace: true });
        } else {
          await USER_STORE().CLEAR_USER_INFO();
          next({ path: '/' });
        }
      } else {
        if (to.matched.length <= 0) {
          next('/404');
          return;
        }
        next();
      }
    }
  // 若未获取到token
  } else {
    // 若路由存在于免登录白名单
    if (WHITE_ROUTER_LIST.includes(to.path)) {
      // 直接进入
      next();
    } else {
      // 否则进入登录页面
      next('/login');
    }
  }
});

export default router;