import { API_REQUEST } from '~/api/config';
import {
  UserInfoRespType,
  UpdateUserPhoneReqDataType,
  UpdateUserPwdReqDataType,
  SubmitEntCertReqType,
  EntCertRespType
} from '~/models/user';

const API_USER = {
  // 获取用户信息
  getUserInfo () {
    return API_REQUEST.get<UserInfoRespType>({ url: '/getInfo' });
  },
  // 修改用户手机号发送验证码
  sendUpdateUserPhoneSMSCode (phone: string) {
    return API_REQUEST.post({
      url: '/system/user/updateUserPhone/sendSms',
      data: { phonenumber: phone }
    });
  },
  // 修改用户手机号
  updateUserPhone (data: UpdateUserPhoneReqDataType) {
    return API_REQUEST.put({
      url: '/system/user/updateUserPhone',
      data
    });
  },
  // 修改用户头像
  updateUserAvatar (data: { avatar: string }) {
    return API_REQUEST.post({
      url: 'system/user/profile/avatar',
      data
    });
  },
  // 修改用户密码
  updateUserPassword (params: UpdateUserPwdReqDataType) {
    return API_REQUEST.put({
      url: '/system/user/profile/updatePwd',
      params
    });
  },
  // 查询企业认证状态
  getEntCertStatus (userId: number) {
    return API_REQUEST.get<EntCertRespType>({
      url: 'sysrealnameauthentication/getStatus',
      params: { userId }
    });
  },
  // 企业认证提交
  submitEntCert (data: SubmitEntCertReqType) {
    return API_REQUEST.put({
      url: 'sysrealnameauthentication/submitInfo',
      data
    });
  }
};

export default API_USER;