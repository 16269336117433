
export const REQUEST_CONFIG = {
  // 接口调用计数
  retryCount: 0,
  // 超时时间
  timeout: 10 * 1000,
  // 请求体最大体积
  maxBodyLength: 5 * 1024 * 1024,
  // 设置同源 / 跨域
  withCredentials: true,
  // 接口是否可终止 默认所有接口可以被终止
  canBeCancel: true,
  // 是否禁用弹框警告
  notReport: false,
  // 接口是否需要登录
  needLogin: true,
  // 请求头
  headers: {
    'Cache-Control': 'no-store'
  }
};

export const ERROR_CODE: Record<string | number, string> = {
  '401': '认证失败，无法访问系统资源',
  '403': '当前操作没有权限',
  '404': '访问资源不存在',
  'default': '系统未知错误，请反馈给管理员'
};