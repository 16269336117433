import { API_REQUEST } from '~/api/config';
import {
  LoginReqDataType,
  PhoneLoginReqDataType,
  LoginRespType,
  VerifyCodeRespType
} from '~/models/login';
import { transFormData } from '~/utils/util';

const API_LOGIN = {
  // 登录
  login (data: LoginReqDataType) {
    return API_REQUEST.post<LoginRespType>({
      url: '/agent/login/up',
      data,
      needLogin: false
    });
  },
  // 手机号验证码登录
  phoneLogin (data: PhoneLoginReqDataType) {
    return API_REQUEST.post<LoginRespType>({
      url: '/agent/login',
      data,
      needLogin: false
    });
  },
  // 发送登录短信验证码
  sendLoginSMSCode (phone: string) {
    const data = transFormData({ phone });
    return API_REQUEST.post({
      url: '/agent/login/sendSms',
      data,
      needLogin: false
    });
  },
  // 获取验证码
  getLoginCode () {
    return API_REQUEST.get<VerifyCodeRespType>({
      url: '/captchaImage',
      headers: { isToken: false },
      needLogin: false
    });
  },
  // 登出
  logout () {
    return API_REQUEST.post({
      url: '/logout',
      needLogin: false
    });
  }
};

export default API_LOGIN;