import axios from 'axios';
import { HttpConfigType, RequestParamsType, HttpResponseType } from './models';
import { nanoid } from 'nanoid';
import { HTTP_STORE } from '~/stores/http';
import { USER_STORE } from '~/stores/user';
import { local } from '~/utils/cache';
import { STORAGE_KEYS } from '~/const/storage-keys';
import { ElMessage, ElNotification } from 'element-plus';
import { ERROR_CODE } from './const';
import router from '~/router';

const LOGOUT = { loading: false };

// 转换get请求参数
const transGetParams = (params: any) => {
  const result: string[] = [];
  const keys = Object.keys(params);
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    const value = params[key];
    if (value) {
      if (typeof value === 'object') {
        const valueKeys = Object.keys(value);
        for (let j = 0; j < valueKeys.length; j += 1) {
          const valueKey = valueKeys[j];
          if (value[valueKey]) {
            const resultKey = encodeURIComponent(`${key}[${valueKey}]`);
            const resultValue = encodeURIComponent(value[valueKey]);
            result.push(`${resultKey}=${resultValue}`);
          }
        }
      } else {
        result.push(`${encodeURIComponent(`${key}`)}=${encodeURIComponent(value)}`);
      }
    }
  }
  return result.join('&');
};

// 登出
const logout = async (config: RequestParamsType) => {
  HTTP_STORE().CHANGE_LOGGING_VALUE(true);
  if (!LOGOUT.loading) {
    LOGOUT.loading = true;
    await USER_STORE().USER_LOGOUT();
    setTimeout(() => {
      HTTP_STORE().CANCEL_REQUEST();
      router.replace('/login');
      HTTP_STORE().CHANGE_LOGGING_VALUE(false);
      LOGOUT.loading = false;
    }, 100);
  }
  return Promise.reject({ config, code: 'ERR_TOKEN_NOT_EXIST', message: '请重新登录' });
};

export const REQUEST = (config: HttpConfigType) => {
  const instance = axios.create(config);
  const { interceptors: { request: requestInterceptor, response: responseInterceptor } } = instance;
  
  // 全局请求拦截
  requestInterceptor.use((config: RequestParamsType) => {
    // 获取token
    const token = local.get<string>(STORAGE_KEYS.TOKEN);
    // 向headers中添加Authorization
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    // 判断接口是否可以终止请求
    if (config.canBeCancel) {
      // 生成终止请求实例
      const abort = new AbortController();
      // 生成唯一id
      const uuid = nanoid();
      // 记录唯一id
      config.requestUUID = uuid;
      // 记录唯一id 及 终止请求实例
      const { abortRequestList } = HTTP_STORE();
      abortRequestList.set(uuid, abort);
      // 终止请求标记
      config.signal = abort.signal;
    }
    if (HTTP_STORE().logging && config.needLogin) {
      return Promise.reject({ config, code: 'ERR_LOGGING', message: '登录中' });
    }
    if (config.method === 'get' && config.params) {
      const transParams = transGetParams(config.params);
      config.url = `${config.url}${transParams ? `?${transParams}` : ''}`;
      config.params = {};
    }
    // 返回请求配置参数
    return config;
  });

  // 全局响应拦截
  responseInterceptor.use((response: HttpResponseType) => {
    const { config, data } = response;
    // 判断是否为可终止接口
    if (config.canBeCancel && config.signal && config.requestUUID) {
      // 删除abort列表中缓存的abort实例
      const { abortRequestList } = HTTP_STORE();
      abortRequestList.delete(config.requestUUID);
    }
    if (config.responseType === 'blob') {
      const { code } = data;
      if (!code) {
        return data;
      }
    }
    const { code, msg } = data;
    const message = ERROR_CODE[code] || msg || ERROR_CODE['default'];
    if (code === 401) {
      return logout(config);
    }
    if (code === 500) {
      ElMessage.error(message);
      return Promise.reject(response);
    }
    if (code === 601) {
      ElMessage.warning(message);
      return Promise.reject(response);
    }
    if (code !== 200) {
      ElNotification.error({ title: message });
      return Promise.reject(response);
    }
    return data;
  }, (err: any) => {
    const { config } = err;
    // 判断接口是否可以被关闭
    if (config.canBeCancel && config.requestUUID) {
      // 删除abort列表中缓存的abort实例
      const { abortRequestList } = HTTP_STORE();
      abortRequestList.delete(config.requestUUID);
    }
    // 响应失败拦截
    // 若当前接口需要登陆
    const { message, response } = err;
    const { code, error_description: errDesc } = response ? response.data : { error_description: '', code: 200 };
    // 若当前接口超时
    if (
      message.indexOf('timeout') >= 0 ||
      (errDesc && errDesc.indexOf('Read timed out') >= 0)
    ) {
      if (config) {
        if (
          typeof config.retry === 'number'
          && typeof config.retryCount === 'number'
          && typeof config.retryDelay === 'number'
        ) {
          // 接口重试调用
          if (config.retryCount < config.retry) {
            // 计数器加1
            config.retryCount += 1;
            // 按retryDelay时长分次调用超时接口
            return new Promise(resolve => {
              setTimeout(() => resolve(true), config.retryDelay);
            }).then(() => {
              return instance(config);
            });
          }
          // 重试到达上限 弹框警告
          if (!config.notReport) {
            ElMessage.error('当前网络不稳定，请稍后再试');
          }
        }
      }
      return Promise.reject(err);
    }
    // 接口鉴权
    if (code === 401) {
      return logout(config);
    }
    // 弹框警告
    if (!config.notReport && (response?.data.msg || response?.data.error_description)) {
      ElMessage.error(response.data.msg || response.data.error_description);
    }
    return Promise.reject(err);
  });

  const request = <T>(config: HttpConfigType): Promise<T> => {
    return new Promise((resolve, reject) => {
      instance.request<T, T>(config).then(res => resolve(res)).catch(err => reject(err));
    });
  };

  const get = <T>(config: HttpConfigType) => {
    return request<T>({ ...config, method: 'GET' });
  };

  const post = <T>(config: HttpConfigType) => {
    return request<T>({ ...config, method: 'POST' });
  };

  const del = <T>(config: HttpConfigType) => {
    return request<T>({ ...config, method: 'DELETE' });
  };

  const put = <T>(config: HttpConfigType) => {
    return request<T>({ ...config, method: 'PUT' });
  };

  return { get, post, delete: del, put };
};