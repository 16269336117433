export const session = {
  get: <T>(key: string) => {
    const data = sessionStorage.getItem(key);
    if (key && data) {
      if (typeof data === 'object') {
        return JSON.tsParse<T>(data);
      }
      return data as T;
    }
  },
  set: (key: string, value: any) => {
    const data = typeof value === 'object' ? JSON.stringify(value) : value;
    sessionStorage.setItem(key, data);
  },
  remove: (key: string) => {
    sessionStorage.removeItem(key);
  }
};

export const local = {
  get: <T>(key: string) => {
    const data = localStorage.getItem(key);
    if (key && data) {
      if (typeof data === 'object') {
        return JSON.tsParse<T>(data);
      }
      return data as T;
    }
  },
  set: (key: string, value: any) => {
    const data = typeof value === 'object' ? JSON.stringify(value) : value;
    localStorage.setItem(key, data);
  },
  remove: (key: string) => {
    localStorage.removeItem(key);
  }
};