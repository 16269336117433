import { RouteRecordRaw } from 'vue-router';

export const pagesRoutes: RouteRecordRaw[] = [
  {
    path: '/home',
    name: 'home',
    meta: { title: '首页' },
    component: () => import('~/views/pages/home/index.vue')
  },
  {
    path: '/base-info',
    name: 'base-info',
    redirect: '/base-info/index',
    component: () => import('~/views/pages/base-info/view.vue'),
    children: [
      {
        path: 'index',
        name: 'base-info-index',
        meta: { title: '基本信息' },
        component: () => import('~/views/pages/base-info/index.vue'),
      },
      {
        path: 'create',
        name: 'create-base-info',
        meta: { title: '实名认证' },
        component: () => import('~/views/pages/base-info/create.vue')
      }
    ]
  },
  {
    path: '/contract',
    name: 'contract',
    meta: { title: '合同签约' },
    component: () => import('~/views/pages/contract/index.vue'),
  },
  {
    path: '/bill',
    name: 'bill',
    meta: { title: '对账单' },
    redirect: '/bill/list',
    component: () => import('~/views/pages/bill/index.vue'),
    children: [
      {
        path: 'list',
        name: 'bill-list',
        meta: { title: '对账单列表' },
        component: () => import('~/views/pages/bill/list/index.vue')
      },
      {
        path: 'detail/:id',
        name: 'bill-detail',
        meta: { title: '对账单明细' },
        component: () => import('~/views/pages/bill/detail/index.vue')
      }
    ]
  },
  {
    path: '/balance',
    name: 'balance',
    meta: { title: '结算单' },
    redirect: '/balance/list',
    component: () => import('~/views/pages/balance/index.vue'),
    children: [
      {
        path: 'list',
        name: 'balance-list',
        meta: { title: '结算单列表' },
        component: () => import('~/views/pages/balance/list/index.vue')
      },
      {
        path: 'detail/:id',
        name: 'balance-detail',
        meta: { title: '结算单明细' },
        component: () => import('~/views/pages/balance/detail/index.vue')
      }
    ]
  },
  {
    path: '/bank-info',
    name: 'bank-info',
    meta: { title: '银行信息' },
    component: () => import('~/views/pages/bank-info/index.vue')
  },
  {
    path: '/notice',
    name: 'notice',
    meta: { title: '消息中心' },
    redirect: '/notice/list',
    component: () => import('~/views/pages/notice/index.vue'),
    children: [
      {
        path: 'list',
        name: 'notice-list',
        meta: { title: '消息列表' },
        component: () => import('~/views/pages/notice/list/index.vue')
      },
      {
        path: 'detail/:id',
        name: 'notice-detail',
        meta: { title: '消息详情' },
        component: () => import('~/views/pages/notice/detail/index.vue')
      }
    ]
  },
  {
    path: '/framework',
    name: 'framework-agreeemnt',
    meta: { title: '框架协议' },
    component: () => import('~/views/pages/framework-agreement/index.vue'),
  },
  {
    path: '/rebate',
    name: 'rebate-agreeemnt',
    meta: { title: '返佣协议' },
    component: () => import('~/views/pages/rebate-agreement/index.vue'),
  },
];