import { defineStore } from 'pinia';
import { ref } from 'vue';
import API_LOGIN from '~/api/login';
import API_USER from '~/api/user';
import { STORAGE_KEYS } from '~/const/storage-keys';
import { LoginReqDataType, PhoneLoginReqDataType } from '~/models/login';
import { EntCertStatusType, UserInfoType } from '~/models/user';
import { local } from '~/utils/cache';

export const USER_STORE = defineStore('USER_STORE', () => {

  const userInfo = ref<UserInfoType>();
  const saleInfo = ref<UserInfoType>();
  const authStatus = ref<EntCertStatusType>();
  
  // 用户登录
  const USER_LOGIN = async (data: LoginReqDataType) => {
    const res = await API_LOGIN.login(data).catch(() => ({ token: '' }));
    if (res.token) {
      local.set(STORAGE_KEYS.TOKEN, res.token);
      return true;
    }
    return false;
  };

  // 用户手机号登录
  const USER_PHONE_LOGIN = async (data: PhoneLoginReqDataType) => {
    const res = await API_LOGIN.phoneLogin(data).catch(() => ({ token: '' }));
    if (res.token) {
      local.set(STORAGE_KEYS.TOKEN, res.token);
      return true;
    }
    return false;
  };

  // 修改用户头像
  const SET_USER_AVATAR = (avatar: string) => {
    if (userInfo.value) {
      userInfo.value.avatar = avatar;
    }
  };

  // 获取用户信息
  const GET_USER_INFO = async () => {
    try {
      const res = await API_USER.getUserInfo();
      const { user, sales } = res;
      userInfo.value = user;
      if (sales) {
        saleInfo.value = sales;
      }
      await GET_ENT_CERT_STATUS();
      return true;
    } catch {
      return false;
    }
  };

  // 获取企业授权状态
  const GET_ENT_CERT_STATUS = async () => {
    if (userInfo.value) {
      const res = await API_USER.getEntCertStatus(userInfo.value.userId);
      authStatus.value = res.data;
    }
  };

  // 清空信息
  const CLEAR_USER_INFO = () => {
    // 清空token
    local.remove(STORAGE_KEYS.TOKEN);
    // 清空用户信息
    userInfo.value = undefined;
  };

  // 登出
  const USER_LOGOUT = async () => {
    try {
      await API_LOGIN.logout();
      CLEAR_USER_INFO();
      return true;
    } catch {
      return false;
    }
  };

  return {
    userInfo,
    saleInfo,
    authStatus,
    USER_LOGIN,
    USER_PHONE_LOGIN,
    SET_USER_AVATAR,
    GET_USER_INFO,
    GET_ENT_CERT_STATUS,
    CLEAR_USER_INFO,
    USER_LOGOUT
  };

});